import { useSetRecoilState } from "recoil";
import { windowWidthState } from "../recoil/atom";

export const WindowEvents = () => {
    const setWidth = useSetRecoilState(windowWidthState);

    const handleResize = () => {
        if(window.innerWidth > 1200){
            setWidth(750);
        } else {
            setWidth(window.innerWidth*0.9);
        }
    };

    window.addEventListener("resize", handleResize)
    return () => {
        window.removeEventListener("resize", handleResize)
    }
}