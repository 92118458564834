import { RecoilRoot } from 'recoil';
import './App.css';

import { Home } from './components/Home/Home';
import { WindowEvents } from './utils/windowEvents';

function App() {
  return (
    <RecoilRoot>
      <Home/>
      <WindowEvents />
    </RecoilRoot>
  );
}

export default App;
