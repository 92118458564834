import React, {useState, useEffect} from 'react';
import { toFixedWithLocale } from '../../helper/_common';
import { useWindowChangeEffect } from '../../hooks/useWindowChangeEffect';
import { BarData, InvestmentChart } from '../BarChart/BarChart';
import { InputMinMax } from '../InputMinMax/InputMinMax';

export const Home = () => {
    // Inputs
    const [once, setOnce] = useState<number | null>(10000);
    const [monthly, setMonthly] = useState<number | null>(100);
    const [period, setPeriod] = useState<number | null>(10);
    const [returnAmount, setReturnAmount] = useState<number | null>(10);
    const [barData, setBarData] = useState<BarData[]>([])

    const onceValue = once || 0;
    const monthlyValue = monthly || 0;
    const periodValue = period || 0;
    const returnAmountValue = returnAmount || 0;

    console.log('rerender');
    

    // sums
    const [sumMonthly, setSumMonthly] = useState(0);
    const [sumReturnAmount, setSumReturnAmount] = useState(0);

    // Sparleistung
    const [overallSaved, setOverallSaved] = useState(0);

    const [width, setWidth] = useState(window.innerWidth*0.8);
    const monthlyInterest = 1+(returnAmountValue/100/12);

    const handleResize = () => {
        if(window.innerWidth > 1200){
            setWidth(750);
        }
        else if (window.innerWidth < 700){
            setWidth(window.innerWidth*0.8);
        }
        else {
            setWidth(window.innerWidth*0.6);
        }
    };

    useEffect(() => {
        handleResize();
    }, []);

    useWindowChangeEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    });

    const calcSparleistungMonthly = () => {
        const regelmaessigeZahlungGesamt = monthlyValue*12*periodValue;

        const barData: BarData[] = [...Array(period).keys()].map(year => {

            const currentReturnAmount: number = [...Array(12*(year+1)).keys()].reduce(
                (acc, prev) => {
                    const currentOverall = onceValue + acc + monthlyValue*prev
                    return currentOverall * monthlyInterest - monthlyValue*prev - onceValue
                }, 0
            );

            return {
                "name": (year+1).toString(),
                "once": onceValue,
                "monthly": monthlyValue*(year+1)*12,
                "interestValue": +currentReturnAmount.toFixed() || 0
            }
        });

        
        setBarData(barData);

        const lastBar = barData[barData.length-1]
        const total = (lastBar?.interestValue + lastBar?.monthly + lastBar?.once) || 0;

        setSumMonthly(monthlyValue * 12 * periodValue);
        setSumReturnAmount(+(total - onceValue - regelmaessigeZahlungGesamt).toFixed());
        setOverallSaved(+total.toFixed(2).toLocaleString());
    }

    useEffect(() => {
        // calcSparleistungYearly()
        calcSparleistungMonthly()
    }, [returnAmount, onceValue, period, monthly]);


    const handleChangeOnce = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOnce(+event.target.value);
    };
    const handleChangeMonthly = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMonthly(+event.target.value);
    };
    const handleChangeInterestValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(+event.target.value);
        
        setReturnAmount(+event.target.value);
    };
    const handleChangePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPeriod(+event.target.value);
    };

    return (
        <div className="container">
            <h1>Rendite-Rechner</h1>
            <div className="input-item once">
                <h3 className="input-headline">Einmalige Einzahlung</h3>
                <input type="range" value={onceValue} onChange={e => handleChangeOnce(e)} min='0' max='50000' step='50' name="einmaligeZahlung-range" id="einmalig-range" />
                <InputMinMax min={"0"} max={"50.000"}/>
                <input className="input-numerical" value={once || ""} onChange={e => handleChangeOnce(e)}/>
            </div>
            <div className="input-item monthly">
                <h3 className="input-headline">Regelmäßige monatliche Einzahlung</h3>
                <input type="range" value={monthlyValue} onChange={e => handleChangeMonthly(e)} min='25' max='1500' step='25' name="regelmaessigeZahlung-range" id="einmalig-range" />
                <InputMinMax min={"25"} max={"1.500"}/>
                <input className="input-numerical" value={monthly || ""} onChange={e => handleChangeMonthly(e)}/>
            </div>
            <div className="input-item return-value">
                <h3 className="input-headline">jährliche Wertentwicklung (Rendite) in Prozent</h3>
                <input className="input-numerical" value={returnAmount || ""} onChange={e => handleChangeInterestValue(e)}/>
            </div>
            <div className="input-item">
                <h3 className="input-headline">Anlagedauer in Jahren</h3>
                <input type="range" value={periodValue} onChange={e => handleChangePeriod(e)} min='1' max='50' step='1' name="einmalig-range" id="einmalig-range" />
                <InputMinMax min={"1"} max={"50"}/>
                <input className="input-numerical" value={period || ""} onChange={e => handleChangePeriod(e)}/>
            </div>
            <div className="results-wrapper">
                <h2 className="mb-2 mt-2">Ergebnis</h2>
                <div className="result">
                    <div className="result-set once">
                        <div className="result-label">Einmalige Zahlung:</div>
                        <div className="result-input">{toFixedWithLocale(onceValue)} Euro</div>
                    </div>
                    <div className="result-set monthly">
                        <div className="result-label">Summe Regelmäßige Zahlungen:</div>
                        <div className="result-input">{toFixedWithLocale(sumMonthly)} Euro</div>
                    </div>
                    <div className="result-set return-value">
                        <div className="result-label">Summe Rendite:</div>
                        <div className="result-input">{toFixedWithLocale(sumReturnAmount)} Euro</div>
                    </div>
                </div>
                <div className="total-set">
                    <h4>Gesamte Sparleistung</h4>
                    <p className="total">
                        <span className="total-amount">{toFixedWithLocale(overallSaved)}</span> Euro
                    </p>
                </div>
            </div>
            <InvestmentChart data={barData} width={width} height={300} />
        </div>
    )
}
