import React from 'react';

export type InputMinMaxProps = {
    min: string;
    max: string;
}

export const InputMinMax = ({min, max}: InputMinMaxProps) => {
  return (
    <div className="input-min-max-werte">
        <span className="min-wert input-wert">{min}</span>
        <span className="max-wert input-wert">{max}</span>
    </div>
  )
};
