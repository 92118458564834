import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export type BarData = {
  name: string;
  once: number;
  monthly: number;
  interestValue: number;
}

export type InvestmentChartProps = {
  data: BarData[];
  width: number;
  height: number;
}

export const InvestmentChart: React.FC<InvestmentChartProps> = ({
  data,
  width,
  height
}) => {
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 20,
        right: 15,
        left: -15,
        bottom: 5,
      }} >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" name='Jahre' />
      <YAxis name="Wert" />
      <Tooltip />
      <Legend />
      <Bar dataKey="once" name="einmalig" stackId="a" fill="#ffc633" />
      <Bar dataKey="monthly" name="monatlich" stackId="a" fill="#33adff" />
      <Bar dataKey="interestValue" name="Rendite" stackId="a" fill="#57dd3d" />
    </BarChart>
  );
}
